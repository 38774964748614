import { ProductResponseDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "../../rootState";
import { ProductUsedMutations } from "./mutations";
import { IProductUsedState } from "./states";

export const actions: ActionTree<IProductUsedState, RootState> = {
  /**
   * It fetches the list of products from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchProductUsedOnTimeSpanByPoolId(
    { commit, rootGetters },
    { poolId, startTo, endTo }
  ): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(ProductUsedMutations.SET_IS_LOADING_PRODUCT_USED_LIST, true);
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Management/ProductUsed/GetProductUsedOnTimeSpanByPoolId",
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
            params: {
              poolId: poolId,
              startTo: startTo,
              endTo: endTo,
            },
          }
        )
        .then((res) => {
          // Réécrire la propriété "price" de chaque élément de res.data avec 2 décimales
          const updatedData = res.data.map((item) => {
            return { ...item, netPrice: Math.round(item.netPrice * 100) / 100, publicPrice: Math.round(item.publicPrice * 100) / 100 };
          });

          commit(ProductUsedMutations.SET_PRODUCT_USED_LIST, updatedData);
          commit(ProductUsedMutations.SET_REQUEST_STATE_PRODUCT_USED, res);
          commit(ProductUsedMutations.SET_IS_LOADING_PRODUCT_USED_LIST, false);
          resolve(updatedData);
        })
        .catch((err) => {
          console.error(err);
          commit(ProductUsedMutations.SET_REQUEST_STATE_PRODUCT_USED, err);
          commit(ProductUsedMutations.SET_IS_LOADING_PRODUCT_USED_LIST, false);
          reject(err);
        });
    });
  },

  async fetchProductUsedOnTimeSpanByCustomerId(
    { commit, rootGetters },
    { customerId, startTo, endTo }
  ): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(ProductUsedMutations.SET_IS_LOADING_PRODUCT_USED_LIST, true);
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Management/ProductUsed/GetProductUsedOnTimeSpanByCustomerId",
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
            params: {
              customerId: customerId,
              startTo: startTo,
              endTo: endTo,
            },
          }
        )
        .then((res) => {
          // Réécrire la propriété "price" de chaque élément de res.data avec 2 décimales
          const updatedData = res.data.map((item) => {
            return { ...item, price: item.price.toFixed(2) };
          });

          commit(ProductUsedMutations.SET_PRODUCT_USED_LIST, updatedData);
          commit(ProductUsedMutations.SET_REQUEST_STATE_PRODUCT_USED, res);
          commit(ProductUsedMutations.SET_IS_LOADING_PRODUCT_USED_LIST, false);
          resolve(updatedData);
        })
        .catch((err) => {
          console.error(err);
          commit(ProductUsedMutations.SET_REQUEST_STATE_PRODUCT_USED, err);
          commit(ProductUsedMutations.SET_IS_LOADING_PRODUCT_USED_LIST, false);
          reject(err);
        });
    });
  },
};
